@media print {

	img.w-full,
	img.w-full-lg,
	img.w-full-md,
	img.w-full-sm,
	img.w-full-xs {
		width: auto !important;
		margin: 0 auto !important;
	}

	.btn,
	.pager,
	.pagination {
		display: none !important;
	}

	.slick-list.draggable {
		height: auto !important;
	}

	.slick-initialized .slick-slide,
	.slick-track {
		width: 100% !important;
		left: auto !important;
	}

	.slick-initialized .slick-slide {
		margin-bottom: 15px !important;
	}

	.slick-cloned {
		display: none !important;
	}

	.parallax-mirror {
		display: none !important;
	}

	.wow,
	.wow * {
		opacity: 1 !important;
		visibility: visible !important;
	}

	a:link:after,
	a:visited:after {
		content: " (" attr(href) ")";
		display: none !important;
	}
}