@use 'sass:math';

@mixin repeated-size {
  height: 0;
  width: 0;
}

@mixin triangle ($width, $direction, $color) {
  @if $direction =='up' {
    @include repeated-size;
    border-bottom: math.div($width, 2) solid $color;
    border-left: math.div($width, 2) solid transparent;
    border-right: math.div($width, 2) solid transparent;
  }

  @else if $direction =='left' {
    @include repeated-size;
    border-bottom: math.div($width, 2) solid transparent;
    border-right: math.div($width, 2) solid $color;
    border-top: math.div($width, 2) solid transparent;
  }

  @else if $direction =='right' {
    @include repeated-size;
    border-bottom: math.div($width, 2) solid transparent;
    border-left: math.div($width, 2) solid $color;
    border-top: math.div($width, 2) solid transparent;
  }

  @else {
    @include repeated-size;
    border-left: math.div($width, 2) solid transparent;
    border-right: math.div($width, 2) solid transparent;
    border-top: math.div($width, 2) solid $color;
  }
}