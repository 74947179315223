.reservation-aside-wrapper {
  background-color: $white;
  border-radius: 10px;
  overflow: hidden;
  position: relative;
  padding-right: 0;

  .header {
    background-color: lighten(map.get($colors, 'blue'), 5%);
    color: $white;
    padding: 15px 70px 13px 15px;
    position: relative;

    .category {
      text-transform: uppercase;
      letter-spacing: .1em;
      @include font-size(10);
    }

    .title {
      margin-top: 5px;
      line-height: 1.1em;
      @include font-size(17);

      em {
        font-size: 80%;
      }
    }

    .button-edition {
      background-color: rgba(0, 0, 0, 0.15);
      color: $white;
      padding-top: 5px;
      position: absolute;
      height: 100%;
      right: 0;
      top: 0;
      width: 55px;
      transition: all .1s ease-in-out;

      .material-icons {
        transition: all .1s ease-in-out;
        @include font-size(26);
      }

      &:hover {
        color: map.get($colors, 'white');
        .material-icons {
          transform: scale(.9);
        }
      }
    }
  }


  .car-icon {
    padding: 5px 0 5px 25px;
    position: relative;

    .text {
      display: block;
      line-height: 1.1em;
      @include font-size(12);
    }

    .material-icons {
      color: map.get($colors, 'blue');
      position: absolute;
      left: 0;
      margin-top: -10px;
      top: 50%;
      @include font-size(18);
    }
  }



  .options-wrapper {
    background-color: lighten(map.get($colors, 'black'), 75%);
    padding: 15px 20px 10px;

    &--item {
      align-items: center;
      color: map.get($colors, 'blue');
      display: flex;
      justify-content: space-between;
      line-height: 1.2em;
      padding: 10px 0;
      position: relative;
      @include font-size(13);

      &.with-border {
        border-top: 1px solid lighten(map.get($colors, 'black'), 60%);
      }

      &.arrival-departure {
        background-color: $white;
        border-radius: 5px;
        border: 1px solid lighten(map.get($colors, 'black'), 60%);
        display: block;
        line-height: 1.4em;      
        margin-bottom: 10px;
        padding-left: 30px;
        position: relative;
        @include font-size(13);

        strong {
          font-family: $font-bold;
        }

        .material-icons {
          color: map.get($colors, 'blue');
          left: 5px;
          margin-top: -12px;
          position: absolute;
          top: 50%;
        }
      }

      .title {
        font-family: $font-bold;
        text-transform: uppercase;
        @include font-size(14);
      }

      .price {
        position: absolute;
        font-family: $font-bold;
        right: 0;
        top: 10px;
        @include font-size(14);
      }
    }



    .txt-info {
      border-top: 1px solid lighten(map.get($colors, 'black'), 75%);
      color: lighten(map.get($colors, 'black'), 75%);
      padding: 10px 0 0;
      font-style: italic;
      line-height: 1.2em;
      @include font-size(12);
    }
  }

  .button-wrapper {
    background-color: map.get($colors, 'black');
    color: $white;
    display: block;
    font-family: $font-bold;
    letter-spacing: .05em;
    padding: 15px;
    text-align: center;
    text-transform: uppercase;
    width: 100%;
    transition: all .1s ease-in-out;
    @include font-size(15);

    .material-icons {
      @include font-size(15);
    }
    
    > * {
      display: inline-block;
      transition: all .1s ease-in-out;
      vertical-align: middle;
    }

    &:hover {
      background-color: map.get($colors, 'orange');
    }
  }

  // @include media-breakpoint-down(lg) {
  //   .header {
  //     padding-top: px(9);
  //     padding-bottom: px(9);

  //     .title {
  //       margin-top: 0;
  //     }
  //   }

  //   .content-wp {
  //     position: relative;
  //     display: flex;
  //     flex-flow: row nowrap;
  //     align-items: center;
  //     border: 1px solid lighten(map.get($colors, 'black'), 75%);
  //     border-radius: 0 0 8px 8px;

  //     .car-wp {
  //       flex: 0 0 45%;
  //       max-width: 45%;
  //       padding: 0 px(15) px(10);
  //     }

  //     .options-wp {
  //       flex: 0 0 55%;
  //       max-width: 55%;
  //       position: relative;
  //       z-index: 2;
  //       padding: px(10) px(25);

  //       .item {
  //         .price {
  //           top: px(9);
  //         }

  //       }
  //     }

  //     &:after {
  //       content: '';
  //       width: 55%;
  //       height: 100%;
  //       position: absolute;
  //       top: 0;
  //       right: 0;
  //       background-color: lighten(map.get($colors, 'black'), 75%);
  //     }
  //   }

  //   .button-wp {
  //     display: block;
  //     width: 240px;
  //     margin: px(15) auto 0;
  //     border-radius: 8px;
  //     padding-top: px(8);
  //     padding-bottom: px(8);
  //     font-size: px(14);
  //   }
  // }

  // @include media-breakpoint-down(sm) {
  //   .content-wp {
  //     display: block;

  //     .car-wp,
  //     .options-wp {
  //       display: block;
  //       width: 100%;
  //       max-width: 100%;
  //     }

  //     &:after {
  //       display: none;
  //     }
  //   }
  // }
}

.reservation-aside-button-cgv {
  color: map.get($colors, 'black') !important;
  display: block;
  margin-top: 10px;
  text-decoration: underline;
  text-align: center;
  width: 100%;
}

.reservation-step-3 {
  &--total {
    align-items: center;
    background-color: lighten(map.get($colors, 'blue'), 0%);
    border-radius: 10px;
    color: map.get($colors, 'blue');
    display: flex;
    justify-content: space-between;
    margin-top: 30px;
    padding: 10px 10px 10px 15px;
  
    .title {
      color: $white;
      font-family: $font-bold;
      text-transform: uppercase;
      @include font-size(20);
    }
  
    .price {
      background-color: $white;
      border-radius: 5px;
      border: 0;
      font-family: $font-bold;
      padding: 10px;
      text-align: center;
      width: 130px;
      @include font-size(22);
    }
  }  
  
  &--button-submit {
    background-color: map.get($colors, 'orange');
    border-radius: 10px;
    color: $white;
    display: block;
    font-family: $font-bold;
    margin: 30px auto 0;
    padding: 10px 20px 10px 75px;
    overflow: hidden;
    position: relative;
    transition: all .2s ease-in-out;
  
    .icon-left {
      background-color: rgba(0, 0, 0, 0.1);
      height: 100%;
      padding-top: 13px;
      left: 0;
      position: absolute;
      top: 0;
      width: 50px;
  
      .icon-left {
        transition: all .2s ease-in-out;
      }
    }
  
    .icon-right,
    .text {
      display: inline-block;
      vertical-align: middle;
    }
  
    .text {
      text-transform: uppercase;
      @include font-size(20);
    }
  
    .icon-right {
      position: relative;
      top: 2px;
    }
  
    &:hover {
      background-color: darken(map.get($colors, 'orange'), 5%);
      
      .icon-left {
        .material-icons {
          transform: scale(.9);
        }
      }
    }
  }
}
