.reservation-step-2 {
  &--item {
    border-radius: 10px;
    color: map.get($colors, 'black');
    position: relative;
    height: 100%;
    padding-bottom: 60px;

    &.except-basic {
      .header {
        background-color: lighten(map.get($colors, 'black'), 70%);
        border: 1px solid lighten(map.get($colors, 'black'), 65%);
        color: lighten(map.get($colors, 'black'), 20%);

        .button-detail {
          &:hover {
            color: lighten(map.get($colors, 'black'), 20%);
          }
        }
      }
    }

    .header {
      background-color: lighten(map.get($colors, 'blue'), 5%);
      border-radius: 10px 10px 0 0;
      color: $white;
      overflow: hidden;
      padding: 15px 80px 15px 15px;
      position: relative;

      .title {
        font-family: $font-bold;
        @include font-size(20);
      }
      .price {
        font-family: $font-regular;
        margin-top: 5px;
        @include font-size(16);
      }

      .button-detail {
        align-items: center;
        justify-content: center;
        background-color: rgba(0, 0, 0, 0.15);
        display: flex;
        flex-flow: row wrap;
        height: 100%;
        padding: 20px 0;
        position: absolute;
        top: 0;
        right: 0;
        text-transform: uppercase;
        text-align: center;
        width: 80px;
        @include font-size(10);

        .material-icons {
          margin-right: 2px;
          @include font-size(14);
        }

        &:hover {
          color: $white;
        }

        > * {
          display: block;
        }
      }
    }

    .header-orange{
      background-color: lighten(map.get($colors, 'orange'), 5%);
    }

    .content {
      border-left: 1px solid lighten(map.get($colors, 'black'), 65%);
      border-right: 1px solid lighten(map.get($colors, 'black'), 65%);
      height: calc(100% - 70px);
      padding: 15px 20px;

      .item {
        padding: 10px 30px 10px 0;
        line-height: 1.2em;
        position: relative;

        .more-info-icon {
          color: lighten(map.get($colors, 'black'), 20%);
          margin-top: -10px;
          position: absolute;
          right: 0;
          top: 50%;
          z-index: 2;

          .material-icons {
            @include font-size(18);
          }
        }
        &:not(:first-child) {
          border-top: 1px solid lighten(map.get($colors, 'black'), 65%);
        }
      }
    }

    .footer {
      background-color: map.get($colors, 'white');
      border-radius: 0 0 10px 10px;
      border: 1px solid lighten(map.get($colors, 'black'), 65%);
      bottom: 0;
      color: $white;
      left: 0;
      overflow: hidden;
      position: absolute;
      width: 100%;

      .reservation-step-3--item {
        border: none;
        border-radius: 0;
        overflow: hidden;

        label {
          padding-top: 10px !important;
        }
      }
    }
  }
}
