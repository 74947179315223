@font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
  src: url('fonts/MaterialIcons-Regular.woff') format('woff');
}

@font-face {
  font-family: 'museo100';
  src:url("fonts/museo_100-webfont.woff") format('woff');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'museo300';
  src:  url("fonts/museo_300-webfont.woff") format('woff');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'museo500';
  src: url("fonts/museo_500-webfont.woff") format('woff');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'museo700';
  src: url("fonts/Museo-700-webfont.woff") format('woff');
  font-weight: normal;
  font-style: normal;
}

$font-light: 'museo100', sans-serif;
$font-regular: 'museo300', sans-serif;
$font-bold: 'museo700', sans-serif;

$fonts: (
  (title-1, 50, $font-bold, normal, 1.2em, title),
  (title-2, 32, $font-bold, normal, 1.2em, title),
  (title-3, 24, $font-bold, normal, 1.15em, title),
  (title-4, 18, $font-bold, normal, 1.2em, title),
  (text-1, 16, $font-bold, normal, 1.2em, text),
  (text-2, 14, $font-bold, normal, 1.2em, text),
  (text-3, 14, $font-bold, normal, 1.2em, text),
  (text-4, 14, $font-regular, normal, 1.2em, text),
  (text-5, 16, $font-regular, normal, 1.2em, text),
  (text-6, 18, $font-regular, normal, 1.2em, text),
);

.material-icons {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 24px; 
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-feature-settings: 'liga';
 }


html {
  color: $black;
  font-size: #{$font-size}px !important;
}

body {
  font-family: $font-regular;
}

@each $label,
$font-size,
$font-weight,
$font-style,
$line-height,
$type in $fonts {
  .#{$label} {
    display: block;
    line-height: $line-height;
    font-style: $font-style;
    font-family: $font-weight;
    @include font-size($font-size);

    @if $label == title-1 {
      text-shadow: 0 4px 4px rgba(0,0,0, .4);
    }

    &.bordered {
      display: block;
      position: relative;

      &:after {
        content: "";
        background-color: map.get($colors, 'orange');
        border-radius: 10px;
        display: block;
        height: 4px;
        margin-top: 8px;
        width: 40px;
      }

      &.centered {
        &:after {
          margin: 10px auto 0 auto;
        }
      }
    }

    @if $type == title and $label != title-4 {

      @include media-breakpoint-down(lg) {
        @include font-size(calc(math.div($font-size, 1.2)));
      }
      @include media-breakpoint-down(md) {
        @include font-size(calc(math.div($font-size, 1.3)));
      }
    }

    @include media-breakpoint-down(sm) {
      @if type == text and size > 15 {
        @include font-size(calc(math.div($font-size,1.3)));
      } 
    }
  }
}