.resa-picker {
  .datepicker-wp,
  .input-wp {
    border: 2px solid map.get($colors,'white');
    background: transparent !important;
    border-radius: 30px;
    display: block;
    color: $white !important;
    height: 40px;
    outline: none !important;
    padding: 0 15px;
    width: 100%;

    input {
      background: transparent !important;
      color: $white !important;
      border: 0;
      height: 100%;
      outline: none !important;
      width: 100%;

      &::placeholder {
        color: $white !important;
      }
    }

    .ui-datepicker-trigger {
      margin-top: -14px;
    }
  }
  .select2-container.select2-container--default .select2-selection--single {
    background: transparent !important;
    border: 2px solid map.get($colors,'white');
    border-radius: 30px;
    .select2-selection__arrow {
      right: 10px;
    }
    .select2-selection__arrow b {
      border-top-color: $white;
    }
    .select2-selection__rendered,
    .select2-selection__placeholder {
      color: $white;
    }
  }

  &.orange {
    .select2-container.select2-container--default .select2-selection--single {
      border: 2px solid map.get($colors,'orange');
    }
  }

  .select-hour  + .select2-container {
    .select2-selection__arrow {
      @extend .sprite;
      @extend .icon-clock;
      right: 15px;
      top: 10px !important;

      b {
        display: none;
      }
    }
  }
}

.reservation-border-left {
  position: relative;
  &:after {
    bottom: 0;
    left: 20px;
    content: "";
    display: block;
    background-color: map.get($colors, 'white');
    height: 100%;
    width: 1px;
    position: absolute;
  }
  @include media-breakpoint-down(xl) {
    &:after {
      left: 10px;
    }
  }

  @include media-breakpoint-down(lg) {
    &:after {
      display: none;
    }
  }
}

.form-resa {
  border-radius: 10px;
  overflow: hidden;

  .row {
    position: relative;
  }

  .label-row {
    align-items: center;
    display: flex;
    left: 0;
    position: absolute;
    top: 0;

    .number {
      color: $white;
      font-family: $font-bold;
      padding-right: 15px;
      margin-right: 10px;
      position: relative;
      @include font-size(24);

      &:after {
        content: "";
        background-color: map.get($colors, 'orange');
        border-radius: 3px;
        height: 40px;
        margin-top: -20px;
        position: absolute;
        right: 0;
        top: 50%;
        width: 3px;
      }
    }
  }

  @include media-breakpoint-down(lg) {
    .label-row {
      top: 13px;
      .number {
        display: none;
      }
    }
  }  

  @include media-breakpoint-down(md) {
    .label-row {
      top: 0;
      .number {
        display: block;
      }
    }
  }  

  @include media-breakpoint-down(sm) {
    .button-regular {
      width: 100%;
    }
    
    .label-row {
      left: auto;
      margin-bottom: 15px;
      position: relative;
      top: auto;
      .number {
        &:after {
          height: 30px;
          margin-top: -15px;
        }
      }
    }
  }    
}

.reservation-wrapper {
  background-image: url('assets/images/resa-cover-bg.jpg');
  background-size: cover;
  background-position: bottom center;
  background-repeat: no-repeat;

  .form-resa {
    margin-top: 50px;
  }


  @include media-breakpoint-down(md) {
    background-attachment :inherit;
    background-size: cover;
  }
  
  .container {
    padding: 150px 90px 70px;
    position: relative;
  }

  @include media-breakpoint-down(xxl) {
    .container {
      display: block;
      padding: 150px 50px 70px;
    }
  }

  @include media-breakpoint-down(lg) {
    .form-resa {
      margin-top: 30px;
    }
    .container {
      padding: 80px 0;
    }
    .car-decoration {
      display: none;
    }
  }  

  @include media-breakpoint-down(md) {
    .container {
      padding: 50px 0;
    }
  }  

  @include media-breakpoint-down(sm) {
    .container {
      padding: 30px 15px;
    }
    .form-resa {
      margin-top: 20px;
    }
  }  
}

.form-resa-inner-page {
  position: relative;
  .form-resa {
    position: relative;
    z-index: 2;
  }
  .reservation-wrapper {
    background: none !important;
  }
  &:after {
    content:"";
    background-color: map.get($colors, 'blue-dark');
    bottom: 0;
    height: 90px;
    left: 0;
    position: absolute;
    z-index: 1;
    width: 100%;

    @include media-breakpoint-down(md) {
      height: 111px;
    }
    @include media-breakpoint-down(sm) {
      height: 167px;
    }
  }
}

