$sprites : (
  (scroll-top, 30px, 30px, 0 0),
  (icon-large-1, 90px, 50px, -40px 0),
  (icon-large-2, 90px, 50px, -130px 0),
  (icon-large-3, 90px, 50px, -220px 0),
  (icon-large-4, 90px, 50px, -310px 0),
  (icon-small-1, 30px, 30px, -80px -52px),
  (icon-small-2, 30px, 20px, -80px -60px),
  (icon-small-3, 30px, 30px, -140px -52px),
  (icon-small-4, 30px, 30px, -170px -52px),
  (icon-clock, 20px, 20px, -220px -50px),
  (icon-calendar, 20px, 20px, -200px -50px),
  (icon-phone, 20px, 20px, -240px -50px),
  (icon-facebook, 20px, 20px, 0 -100px),
  (icon-instagram, 20px, 20px, -30px -100px),
  (arrow-prev, 40px, 40px,  0 -50px),
  (arrow-next, 40px, 40px,  -39px -50px),
  (chevron-bottom-white, 10px, 10px, -260px -50px ),
  (chevron-bottom-orange, 10px, 10px, -270px -50px ),
  (chevron-right, 10px, 10px, -280px -50px ),
);

.sprite {
	background-image: url('assets/icons/sprite.png');
	background-repeat: no-repeat;
	display: inline-block;
	vertical-align: text-top;

  @each $label, $width, $height, $background-position in $sprites {
    &.#{$label} {
      width: $width;
      height: $height;
      background-position: $background-position;
    }
  }
}
