@mixin spacing-generator($size, $type) {
  @if $type ==ma {
    margin: #{$size}px;
  }

  @else if $type ==pa {
    padding: #{$size}px;
  }

  @else if $type ==mv {
    margin-bottom: #{$size}px;
    margin-top: #{$size}px;
  }

  @else if $type ==mh {
    margin-left: #{$size}px;
    margin-right: #{$size}px;
  }

  @else if $type ==mt {
    margin-top: #{$size}px;
  }

  @else if $type ==mr {
    margin-right: #{$size}px;
  }

  @else if $type ==mb {
    margin-bottom: #{$size}px;
  }

  @else if $type ==ml {
    margin-left: #{$size}px;
  }

  @else if $type ==pv {
    padding-bottom: #{$size}px;
    padding-top: #{$size}px;
  }

  @else if $type ==ph {
    padding-left: #{$size}px;
    padding-right: #{$size}px;
  }

  @else if $type ==pt {
    padding-top: #{$size}px;
  }

  @else if $type ==pr {
    padding-right: #{$size}px;
  }

  @else if $type ==pb {
    padding-bottom: #{$size}px;
  }

  @else if $type ==pl {
    padding-left: #{$size}px;
  }
}