.item-services {
  background-color: $white;
  border-radius: 5px;
  box-shadow: 0 0 20px rgba(0,0,0,0.1);
  display: flex;
  flex-flow: column wrap;
  height: 100%;
  justify-content: space-between;
  padding: 20px;

  img {
    border-radius: 3px;
  }

  .title {
    color: map.get($colors, 'blue-light');
    font-family: $font-bold;
    line-height: 1.2em;
    padding-top: 15px;
    @include font-size(20);
  }

  .desc {
    line-height: 1.3em;
    margin-top: 10px;
    padding-bottom: 15px;
    @include font-size(14);
  }

  @include media-breakpoint-down(md) {
    .title {
      font-size: px(18);
    }
  }
  @include media-breakpoint-down(xs) {
    .title {
      font-size: px(16);
    }
  }
}
