.parsley-errors-list {
	color: map.get($colors, 'error');
	margin-top: 5px;
	@include font-size(14);
}

.selectpicker {
	&.parsley-error {
		& + .select2 {
			.select2-selection {
				border-color:  map.get($colors, 'error') !important;

				.select2-selection__placeholder {
					color: map.get($colors, 'error') !important;
				}

				.select2-search__field {
					color: map.get($colors, 'error') !important;
				}
			}
		}
	}
}
.select2-search__field {
  outline: none;
}

.file-picker--input {
	&.parsley-error {
		& +.file-picker--label {
			color: map.get($colors, 'error') !important;
			border-color:  map.get($colors, 'error') !important
		}
	}
}

.form-control {
	&.parsley-error {
		border-color:  map.get($colors, 'error') !important;
		&::placeholder {
			color:map.get($colors, 'error');
		}
	}
}
