$colors: (
  'black': #323232,
  'white': #FFFFFF,
  'grey': #F0F0F0,
  'grey-light': #f2f2f2,
  'blue-light': #064479,
  'blue': #00285F,
  'blue-dark': #011B3C,
  'orange' : #ED5505,
  'orange-alt': #F78B00,
  'error': #a94442,
  'green': #89CE00,
);

@each $name,
$value in $colors {
  .color-#{$name} {
    color: $value;
  }

  .background-#{$name} {
    background-color: $value;
  }

}