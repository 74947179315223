.home-services {
  &--wrapper {
    position: relative;
    
    &:after {
      content:"";
      bottom: 0;
      display: block;
      position: absolute;
      right: -400px;
      z-index: 1;
      @include triangle(800px, up, map.get($colors, 'orange-alt'));
    } 

    div {
      &[class^=col] {
        position: relative;
        z-index: 2;
      }
    }
  }

  &--item {
    background-color: map.get($colors, 'grey');
    box-shadow: 0 4px 4px rgba(0,0,0,.25);
    border-radius: 4px;
    min-height: 250px;
    height: 100%;
    overflow: hidden;
    padding: 30px 290px 60px 30px;
    position: relative;
    z-index: 2;

    @include media-breakpoint-up(md) {
      &--button {
        position: absolute;
        bottom: 30px;
        left: 30px;
      }
    }


    &--image {
      align-items: center;
      display: flex;
      height: 100%;
      overflow: hidden;
      position: absolute;
      right: -30px;
      top: 0;
      transform: skew(-12deg);
      width: 300px;
      
      img {
        margin-left: -30px;
        transform: skew(12deg);
        width: calc(100% + 20px);
      }
    }

    @include media-breakpoint-down(md) {
      min-height: 0;
      padding: 30px;
      
      &--image {
        display: none;
      }

      &--button {
        margin-top: 10px;
      }

    }
    
  }
}

.reservation-car-item {
  overflow: hidden;

  .img-wrapper {
    display: block;
    padding: 0 15px;
    
    img {
      transition: all .1s ease-in-out;
      width: 100%;
    }
  }

  .text-wrapper {
    display: block;
    margin-top: 15px;
    text-align: center;
    transition: all .1s ease-in-out;
  }

  .link {
    display: block;
    width: 100%;

    .footer-wrapper {
      bottom: 0;
      position: absolute;
      padding: 0 30px 30px;
      width: 100%;
    }
  
    &:hover {
      img {
        transform: scale(.95);
        opacity: .8;
      }
      .text-wrapper {
        transform: translateY(-10px);
      }
    }
  }

}
