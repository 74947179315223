#header {
	background-color: map.get($colors, 'blue');
	position: relative;
	padding: 0;
	width: 100%;
	z-index: 10;
	@include transition(all, 0.2s, ease-in-out);
	
	.logo {
		@include media-breakpoint-down(lg) {
			display: block;
			width: 250px;
		}
	}	
}

.button-phone {
	align-items: center;
	color: map.get($colors, 'orange-alt');
	display: flex;
	justify-content: flex-end;
	font-family: $font-bold;
	padding-left: 15px;
	transition: all .2s ease-in-out ;
	@include font-size(18);
	
	.sprite {
		transition: all .2s ease-in-out ;
	}

	&:hover {
		color: map.get($colors, 'orange-alt');
		.sprite {
			transform: scale(.85) translateX(2px) translateY(-1px);
		}
	}

	@include media-breakpoint-down(lg) {
		position: absolute;
		right: 15px;
		top: 28px;
	}

	@include media-breakpoint-down(sm) {
		display: none;
	}
}

#navigation-main {
	display: flex;
	align-items: center;
	justify-content: flex-end;

	@include media-breakpoint-down(sm) {
	}

	.item {
		//flex-grow: 1;
		&:not(:first-child) {
			margin-left: 100px;

			@include media-breakpoint-down(xxl) {
				margin-left: 70px;
			}

			@include media-breakpoint-down(xl) {
				margin-left: 30px;
			}

			@include media-breakpoint-down(xl) {
				margin-left: 47px;
			}

			@include media-breakpoint-down(md) {
				margin-left: 20px;
			}
		}

		@include media-breakpoint-down(sm) {
			margin: 20px 0;
		}
	}

	.link {
		color: map.get($colors, 'white');
		display: block;
		text-align: center;
		position: relative;
		font-family: $font-bold;
		text-transform: uppercase;
		transition: all .2s ease-in-out;
		white-space: nowrap;
		@include font-size(15);

		@include media-breakpoint-down(md) {
			@include font-size(13);
		}

		@include media-breakpoint-down(sm) {
			@include font-size(20);
			&:before {
				display: none !important;
			}
		}		
		
		&:before {
			content: "";
			border-radius: 5px;
			background-color: map.get($colors, 'orange');
			display: block;
			position: absolute;
			bottom: -12px;
			left: 50%;
			margin-left: -15px;
			height: 3px;
			transition: all .2s ease-in-out ;
			opacity: 0;
			width: 30px;
		}
		
		&:focus,
		&:hover {
			color:  map.get($colors, 'orange');
			&:before {
				bottom: -10px;
				opacity: 1;
			}
		}

		&.active {
			color: map.get($colors, 'orange');
		}
	}

	.navigation-sub {
		.item-sub {
			&:not(:first-child) {
				margin-top: 5px;
			}
		}

		.link-sub {
			color: $white;
			display: block;
			font-family: $font-bold;
			line-height: 1.2em;
			padding-left: 12px;
			position: relative;
			transition: all .2s ease-in-out;
		
			&:before {
				content:"";
				height: 5px;
				background-color: $white;
				border-radius: 100%;
				left: 0;
				position: absolute;
				top: 5px;
				width: 5px;
			}

			&:focus,
			&:hover {
				transform: translateX(5px);
			}
	
			&.active {}

			@include media-breakpoint-down(sm) {
				text-align: center;
				padding: 5px 0;
				&:before {
					display: none;
				}
				&:focus,
				&:hover {
					transform: translateX(0);
				}	
				
				@include font-size(16);
			}
	
		}
	}

	@include media-breakpoint-down(lg) {
		display: none;
	}
}

@include media-breakpoint-up(sm) {
	#navigation-main {
		display: flex !important;
	}

	#button--mobile {
		display: none;
	}
}

#button--mobile {
	position: absolute;
	top: 22px;
	right: 20px;
}

.navigation-sub {
	background-color: map.get($colors, 'orange');
	width: calc(100% + 40px);
	padding: 20px;
	margin: 10px 0;
	margin-left: -30px;
	display: none;
	height: auto;
	left: auto;
	opacity: 1;
	position: relative;
	top: auto;
}

li {
	&[data-navsub] {
		position: relative;
		.link {
			&:after {
				content:"";
				@extend .sprite;
				@extend .chevron-bottom-orange;
				margin: 5px 0 0 10px;
				@include media-breakpoint-down(md) {
					transform: scale(.8) translateY(-2px);
				}
				@include media-breakpoint-down(sm) {
					transform: scale(1) translateY(3px);
				}
			}
		}
	}
}

@include media-breakpoint-up(sm) {
	li {
		&[data-navsub] {
			&.focus {
				> .navigation-sub {
					display: block;
					height: auto;
					opacity: 1;
					top: 18px;
					padding: 15px 20px;
					visibility: visible;
				}
			}
		}
	}

	.navigation-sub {
		background-color: map.get($colors, 'orange');
		border-radius: 5px;
		box-shadow: 0 3px 5px rgba(0, 0, 0, 0.2);
		display: none;
		height: 0;
		left: -15px;
		opacity: 0;
		overflow: hidden;
		padding: 0;
		position: absolute;
		width: 225px;
		z-index: 99;
		@include transition(all, 0.1s, ease-in-out);
	}
}

body {
	&.header-small {
		#header {
			@include media-breakpoint-up(lg) {}
		}
	}
}

#header-page {
	background-image: url('assets/images/resa-cover-bg-inner.jpg');
  background-attachment: cover;
  background-position: center;
  background-repeat: no-repeat;
	background-color: map.get($colors, 'black');
	color: $white;
	padding: 70px 0;
	position: relative;
	text-align: center;

	@include media-breakpoint-down(md) {
		padding: 30px 0;
	}

	.container {
		position: relative;
		z-index: 2;
	}
	&:after {
		content: "";
		background-color: rgba(0,0,0, .4);
	 	position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
}

body {
	&.menu-open {
		.header-top {
			padding-bottom: 0;
		}
	}
}