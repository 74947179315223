.reservation-step-4 {
  &--input {
    background-color: lighten(map.get($colors, 'black'), 75%);
    border-radius: 10px;
    border-bottom: 1px solid lighten(map.get($colors, 'black'), 65%);
    padding: 10px 15px;
  
    label {
      color: map.get($colors, 'blue-light');
      font-family: $font-bold;
      text-transform: uppercase;
      @include font-size(12);
    }
    .ui-datepicker-trigger {
      margin-top: -14px;
    }
  
    input,
    textarea,
    .selectpicker-wrapper {
      border: none;
      border-radius: 4px;
      box-shadow: 0 0 5px rgba(0, 0, 0, .05);
      margin-top: 5px;
      outline: none !important;
  
      &:focus {
        box-shadow: none !important;
      }
    }
  }
}


.estimate-form {
  background-color: #fff;
  border: 2px solid map.get($colors, 'grey');
}