#header-reservation {
  background-color: map.get($colors, 'blue');
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  overflow: hidden;
  position: fixed;
  padding: 15px 0;
  top: 0;
  transition: all .2s ease-in-out;
  width: 100%;
  z-index: 10;

  .logo {
    display: block;
    padding: 0;
    position: relative;

    .img-fluid {
      display: block;
      margin: 0 auto;
    }
  }

  .item {
    align-items: center;
    color: $white;
    display: flex;
    justify-content: center;
    font-weight: 600;
    position: relative;

    .txt {
      font-weight: 400;
      text-transform: uppercase;
			@include font-size(14);
    }

    .number {
      background-color: $white;
      border-radius: 25px;
      color: map.get($colors, 'blue');
      font-family: $font-bold;
      height: 25px;
      margin-right: 10px;
      padding-top: 2px;
      text-align: center;
      width: 25px;
      @include font-size(14);
    }
    
    &:not(.first) {
      &:before {
        content: '';
        background-color: $white;
        height: 14px;
        left: 0px;
        margin-top: -7px;
        position: absolute;
        top: 50%;
        width: 1px;
      }
    }

    &.active {
      color: map.get($colors, 'orange');
      
      .txt {
        font-family: $font-bold;
      }
      
      .number {
        background-color: map.get($colors, 'orange');
        color: $white;

        &:after {
          display: none;
        }
      }
    }
  }


@include media-breakpoint-down(lg) {
  display: block;
  padding: 10px 0;
  
  .logo {
    padding: 3px;

    &:after {
      right: -20px;
    }
  }

  .item {
    .txt {
      @include font-size(11);
    }
    .number {
      height: 20px;
      margin-right: 5px !important;
      width: 20px;
      @include font-size(11);
    }

    &:before {
      display: none !important;
    }
  }
}

 @include media-breakpoint-down(md) {
  .row {
    //align-items: flex-start !important;
  }

  .item {
    flex-flow: column wrap;
    padding-top: 8px;

    .number {
      height: 20px;
      margin-right: 0 !important;
      width: 20px;
      @include font-size(11);
      
      &:after {
        display: none;
      }
    }
  
    .txt {
      margin-top: 3px;
      @include font-size(11);
    }
  }
 }

  @include media-breakpoint-down(sm) {
    padding: 5px 0;

    .logo {
      .img-fluid {
        margin: 0;
      }
      
    }

    .col-3 {
      &.col-sm-2 {
        flex: 0 0 120px;
        max-width: 120px;
      }
    }

    .col-9 {
      flex: 0 0 calc(100% - 120px);
      max-width: calc(100% - 120px);
    }

    .row {
      align-items: center !important;
    }

    .item {
      padding-top: 0;

      .txt {
        display: none;
      }
      .number {
        height: 25px;
        padding-top: 4px;
        width: 25px;
        @include font-size(12);
      }
    }
  }
}

.reservation-summary-wrapper {
  background-image: url('assets/images/resa-cover-bg-inner.jpg');
  background-attachment: cover;
  background-position: bottom center;
  overflow: hidden;
  position: relative;
  padding: 50px 0;
  
  &:after {
    content: "";
    background-color: $black;
    height: 100%;
    left: 0;
    opacity: .2;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 1;
  }

  @include media-breakpoint-down(lg) {
    padding: 30px 0;
  }

  @include media-breakpoint-down(sm) {
    padding: 15px 0 15px 0 !important;
  }
}

#reservation-summary {
  background-color: $white;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.15);
  border-radius: 5px;
  display: table;
  margin: 0 auto;
  overflow: hidden;
  position: relative;
  width: 100%;
  z-index: 2;

  .column {
    display: table-cell;
    padding: 20px 0 20px 0;
    position: relative;
    vertical-align: middle;
    width: 37%;

    .list {
      padding: 0 15px 0 50px;
      position: relative;
      height: 100%;

      .place {
        color: map.get($colors, 'blue');
        display: block;
        font-family: $font-bold;
        line-height: 1.2em;
        text-transform: uppercase;
        @include font-size(14);
      }
    
      .date {
        color: ligthen(map.get($colors, 'blue'), 40%);
        display: block;
        font-family: $font-regular;
        line-height: 1.2em;
        margin-top: 3px;
        @include font-size(12);
      }
    }

    .material-icons {
      color: map.get($colors, 'blue');
      left: 15px;
      margin-top: -10px;
      position: absolute;
      top: 50%;
      @include font-size(30);
    }  

    &.type-time {
      .material-icons {
        left: 15px;
        @include font-size(24);
      }

      &:after {
        display: none;
      }
    }

  
    &:after {
      content: '';
      background-color:lighten(map.get($colors, 'black'), 70%);
      display: block;
      height: 100%;
      position: absolute;
      top: 0;
      right: 0;
      width: 1px;
    }
  }



  .button-submit {
    background-color: map.get($colors, 'orange');
    color: $white;
    height: 84px;
    text-align: left;
    padding-left: 15px;
    text-transform: uppercase;
    font-family: $font-bold;
    width: 120px;
    @include font-size(12);

    > * {
      display: inline-block;
      vertical-align: middle;
    }

    &.open {
      .material-icons {
        transform: rotate(180deg);
      }
    }
  }


  @include media-breakpoint-down(lg) {
    .column {
      padding: 15px 0 15px 0;
      width: 42%;
      
      .material-icons {
        left: 5px;
        @include font-size(22);
      }
      
      .list {
        padding-left: 30px;
        .place {
          @include font-size(12);
        }
      }

      &.type-time {
        .material-icons {
          left: 7.5px;
          @include font-size(18);
        }
      }
    }

    .button-submit {
      height: 90px;
      padding-left: 10px;
      width: 110px;
    }
  }

  @include media-breakpoint-down(md) {
    .place {
      @include font-size(11);
    }

    .date {
      margin-top: 2px;
      @include font-size(10);
    }

    .column {
      padding-top: 8px;
      padding-bottom: 8px;

      .list {
        padding-left: 15px;
      }
  
      .material-icons {
        display: none;
      }
    }

    .button-submit {
      height: 90px;
      width: 90px;
      @include font-size(12);

      .material-icons {
        font-size: 18px;
      }
    }
  }

  @include media-breakpoint-down(sm) {
    .column {
      border-bottom: 1px solid lighten(map.get($colors, 'black'), 70%);
      display: block;
      padding-top: 8px;
      padding-bottom: 8px;
      width: 100% !important;

      &.type-time {
        border-bottom: 0;
      }

      .list {
        display: block;
        border-right: none !important;
        padding-left: 32px !important;
        width: 100% !important;

        .place {
          @include font-size(13);
        }
    
        .date {
          margin-top: 0;
          @include font-size(12);
        }        
        
        &:after {
          left: 10px !important;
        }
      }

      .material-icons {
        display: block;
      }

      &:after {
        display: none;
      }
    }
    
    .button-submit {
      text-align: center;
      padding-bottom: 2px;
      padding-left: 0;
      height: 35px;
      width: 100%;
    }
  }
}
