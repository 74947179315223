$buttons: (
  (
    button-regular,
    inline-block,
    14,
    $font-bold,
    uppercase,
    1.2,
    9px 24px 8px,
    center,
    20px,
    $white,
    map.get($colors,'orange'),
    2px solid map.get($colors,'orange'),
    map.get($colors,'orange'),
    $white,
    2px solid map.get($colors,'orange')
  ),
  (
    button-regular-alt,
    inline-block,
    13,
    $font-bold,
    uppercase,
    1.2,
    0,
    center,
    7px,
    map.get($colors,'orange'),
    transparent,
    none,
    map.get($colors,'orange'),
    transparent,
    none,
  ),
);

@each $label,
  $display,
  $font-size,
  $font-family,
  $text-transform,
  $line-height,
  $padding,
  $text-align,
  $border-radius,
  $color,
  $background-color,
  $border,
  $hover-color,
  $hover-background,
  $hover-border in $buttons {
  .#{$label} {
    background-color: $background-color;
    border: $border;
    border-radius: $border-radius;
    color: $color;
    display: $display;
    font-family: $font-family;
    line-height: $line-height;
    padding: $padding;
    text-align: $text-align;
    text-transform: $text-transform;
    transition: all 0.2s ease-in-out;
    @include font-size($font-size);

    &:hover,
    &:focus {
      background-color: $hover-background;
      color: $hover-color!important;
      border: $hover-border;
    }

    &.dropdown-toggle {
      background-color: darken(map.get($colors, 'grey'), 10%);
      color: map.get($colors, 'black');
      border-color: darken(map.get($colors, 'grey'), 10%);
    }

    @if $label == button-regular {
      min-height: 40px;
    }

    @if $label == button-regular-alt {
      position: relative;

      &:before {
        content: "";
        background-color: map.get($colors, 'orange');
        bottom: -2px;
        border-radius: 5px;;
        left: 0;
        height: 2px;
        position: absolute;
        transition: all .2s ease-in-out;
        width: 0;
      }

      &:hover,
      &:focus {
        &:before {
          width: 100%;
        }
      }


      &.with-arrow {
        &:after {
          content:"";
          @extend .sprite;
          @extend .chevron-right;
          margin-top: -6px;
          position: absolute;
          top: 50%;
          right: -15px;
          transition: all .1s ease-in-out;
        }
        &:hover,
        &:focus {
          &:after {
            transform: translateX(-2px);
          }
        }
      }
    }
  }
}

.button {
  &--mobile {
    cursor: pointer;
    display: block;
    margin: 0 auto;
    height: 20px;
    position: relative;
    transform: rotate(0deg);
    width: 50px;
    z-index: 2;
    @include transition(all, 0.2s, ease-in-out);

    span {
      background: $white;
      display: block;
      height: 6px;
      border-radius: 4px;
      left: 0;
      opacity: 1;
      position: absolute;
      transform: rotate(0deg);
      width: 100%;
      @include transition(all, 0.2s, ease-in-out);
    }

    span:nth-child(1) {
      top: 0;
    }

    span:nth-child(2),
    span:nth-child(3) {
      top: 12px;
    }

    span:nth-child(4) {
      top: 24px;
    }
  }

  &--scroll-top {
    bottom: 30px;
    position: fixed;
    right: 30px;
    z-index: 99;

    @include media-breakpoint-down(sm) {
      bottom: 15px;
      right: 15px;
    }

    &:hover,
    &:focus {}
  }
}

.menu-open {
  .button--mobile {
    span:nth-child(1) {
      left: 50%;
      top: 18px;
      width: 0;
    }

    span:nth-child(2) {
      transform: rotate(45deg);
    }

    span:nth-child(3) {
      transform: rotate(-45deg);
    }

    span:nth-child(4) {
      left: 50%;
      top: 18px;
      width: 0;
    }
  }
}
